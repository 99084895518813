@tailwind base;
@tailwind components;
@tailwind utilities;

.BostonLoganCoach {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: #f7f7f7;
  color: #0a1128;
}

/* Dark Mode */
body.dark-mode {
  background-color: #0b0a07;
  color: #efa00b;
}

/* Custom styles for dark mode */
.dark-mode .custom-textfield .MuiInputBase-input {
  color: #c7d5e0;
}

.dark-mode .custom-textfield .MuiInputLabel-root {
  color: #c7d5e0;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #c7d5e0;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: cyan;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: cyan;
}

.dark-mode .card-content {
  color: #ffffff;
}

.image-container {
  position: relative;
  overflow: hidden; /* Ensures the light effect does not overflow the image */
}

/* .image-container img {
    display: block; 
  } */

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start the light effect off-screen to the left */
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  transition: left 0.5s ease; /* Smooth transition for moving the light effect */
  pointer-events: none; /* Ensure the pseudo-element does not block interactions */
}

.image-container:hover::before {
  left: 100%; /* Move the light effect across the image to the right on hover */
}
.Company-Main-Font {
  font-family: "Bad Script", cursive;
  font-weight: 900;
  font-style: normal;
  /* text-shadow: 2px 2px 3px #171a21; */
  letter-spacing: 2px;
  /* text-shadow: #171a21 1px 0 2px; */
}

/* body {
    cursor: url('../public/cursor.svg'), auto; 
    
  } */

/* a, button {
    cursor: url('../public/hand.svg'), pointer; 
  } */
